import React, { useContext, useState } from 'react'
import { Button, TextField, Box, Typography, MenuItem } from '@mui/material'
import isEmail from 'validator/lib/isEmail'
import { UserContext } from 'contexts/UserContextProvider'
import { UserRole } from 'constant'
import { updateUser } from 'api'
import Header from 'components/Header'
import { useNavigate, useParams } from 'react-router-dom'

const UpdateUser = () => {
  const navigate = useNavigate()
  const { users, setUsers } = useContext(UserContext)
  const { userId } = useParams()
  const selectedUser: any = users.find((user: any) => user._id === userId)
  if (!selectedUser) {
    alert('user not exit')
    window.location.pathname = '/users'
  }
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState(selectedUser.email)
  const [role, setRole] = useState(selectedUser.role)
  const [error, setError] = useState(false)

  const handleUpdateUser = async (
    password: string,
    email: string,
    role: string
  ) => {
    if (isEmail(email)) {
      try {
        const result: any = await updateUser(
          selectedUser._id,
          password,
          email,
          role
        )
        const updatedUserIndex = users.findIndex(
          user => user._id === selectedUser._id
        )
        users[updatedUserIndex] = {
          ...users[updatedUserIndex],
          email,
          role
        }
        setUsers([...users])
        navigate('/users')
        if (result.data.success) {
          alert('User updated successfully')
        } else {
          alert('User updated failed. Server error.')
        }
      } catch (error) {
        setError(true)
      }
    } else {
      setError(true)
    }
  }

  return (
    <>
      <Header />
      <Box sx={{ width: '80%', margin: '0 auto', marginTop: '100px' }}>
        <Typography sx={{ textAlign: 'center', fontSize: '40px' }}>
          Username: {selectedUser.username}
        </Typography>
        <TextField
          label='Email'
          fullWidth
          type='email'
          error={error && (!email || !isEmail(email))}
          helperText={
            error &&
            (!email || !isEmail(email)) &&
            'Please enter a valid email address.'
          }
          defaultValue={selectedUser.email}
          sx={{ margin: '20px auto 50px' }}
          onChange={e => {
            setError(false)
            setEmail(e.target.value)
          }}
        />
        <TextField
          label='Password'
          fullWidth
          type='password'
          sx={{ margin: '20px auto 50px' }}
          onChange={e => {
            setError(false)
            setPassword(e.target.value)
          }}
        />
        <TextField
          label='Role'
          fullWidth
          select
          sx={{ margin: '20px auto 50px' }}
          onChange={e => {
            setError(false)
            setRole(e.target.value)
          }}
          defaultValue={selectedUser.role}
        >
          <MenuItem value={UserRole.admin}>{UserRole.admin}</MenuItem>
          <MenuItem value={UserRole.employee}>{UserRole.employee}</MenuItem>
        </TextField>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            handleUpdateUser(password, email, role)
          }}
          sx={{
            display: 'block',
            margin: '20px auto',
            padding: '6px 25px',
            fontSize: 20
          }}
        >
          Submit
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => {
            navigate('/users')
          }}
          sx={{
            display: 'block',
            margin: '20px auto',
            padding: '6px 25px',
            fontSize: 20
          }}
        >
          Pick Another User
        </Button>
      </Box>
    </>
  )
}

export default UpdateUser
