import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField
} from '@mui/material'
import { updateRedisOrder } from 'api'
import Header from 'components/Header'
import { OrderContext } from 'contexts/OrderContextProvider'
import React, { useContext, useEffect, useRef, useState } from 'react'

const UpdateOrderCache = () => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [orderNumber, setOrderNumber] = useState('')
  const { appRoot, setSnackbarAttributes } = useContext(OrderContext)
  const handleSubmit = async () => {
    if (!orderNumber) {
      setError(true)
      return
    }
    setLoading(true)
    const result = await updateRedisOrder(appRoot, orderNumber)
    setLoading(false)
    if (result.data.success) {
      alert('Cache Order Updated')
    } else {
      alert('Fail to Update Cache Order')
    }
  }
  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }
  useEffect(() => {
    inputRef.current?.focus()
  }, [])
  return (
    <>
      <Header />
      <Box
        onKeyDown={handleKeyDown}
        sx={{
          margin: '50px 20px'
        }}
      >
        <TextField
          autoFocus
          inputRef={inputRef}
          label='Order Number'
          fullWidth
          value={orderNumber}
          error={error}
          helperText={error && 'Invalid order number'}
          onChange={e => {
            setError(false)
            setOrderNumber(e.target.value)
          }}
        />
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
        <Button
          variant='contained'
          sx={{ display: 'block', margin: '50px auto', padding: '10px 25px' }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </>
  )
}

export default UpdateOrderCache
