import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import OrderSearchInput from 'components/OrderSearchInput'
import { ProductCard, ProductListSearchBar } from 'components/ProductList'
import Header from 'components/Header'
import { OrderContext } from 'contexts/OrderContextProvider'
import React, { useContext, useState } from 'react'
import { isObjectNotEmpty, getTransferOrderBackColor } from 'utils'

const TransferOrderPicking = () => {
  const { orderContents, products, orderNumber } = useContext(OrderContext)
  const [scrollIndex, setScrollIndex] = useState(1)
  return (
    <>
      <Header label='Transfer Order Picking' />
      {isObjectNotEmpty(orderContents) ? (
        <Box>
          <Box sx={{ backgroundColor: 'rgba(191,205,224,1)', padding: '5px' }}>
            <Typography
              sx={{ textAlign: 'center' }}
            >{`Transfer order: ${orderNumber}`}</Typography>
          </Box>
          <ProductListSearchBar />
          <Box>
            <Typography
              sx={{ lineHeight: '44px', paddingLeft: '10px', fontSize: 14 }}
            >
              Location: {orderContents.location.refName}
            </Typography>
          </Box>
          {products.length > 0 ? (
            <List
              sx={{
                padding: 0,
                maxHeight: 'calc(100vh - 44px - 74px - 34px - 46px)',
                overflowY: 'scroll'
              }}
              onScroll={(event: any) => {
                const target = event.target
                if (
                  target.scrollHeight -
                    target.scrollTop -
                    target.clientHeight <=
                  0
                ) {
                  setScrollIndex(scrollIndex + 1)
                }
              }}
            >
              {products.slice(0, 10 * scrollIndex).map((product: any) => {
                return (
                  <Box key={product.line}>
                    <ListItem
                      sx={{
                        backgroundColor: getTransferOrderBackColor(product),
                        padding: '10px'
                      }}
                    >
                      <ProductCard product={product} />
                    </ListItem>
                    <Divider color='#ccc' />
                  </Box>
                )
              })}
            </List>
          ) : (
            <Typography variant='h2'>
              All items in this order were picked!
            </Typography>
          )}
        </Box>
      ) : (
        <OrderSearchInput />
      )}
    </>
  )
}

export default TransferOrderPicking
