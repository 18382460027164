import {
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Typography
} from '@mui/material'
import { OrderContext } from 'contexts/OrderContextProvider'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
  product: any
}

const ProductCard = ({ product }: Props) => {
  const { pathname } = useLocation()
  const { handleGetProductBin } = useContext(OrderContext)

  useEffect(() => {
    if (product.preferredBin === 'loading') {
      handleGetProductBin(product)
    }
  }, [product.preferredBin])

  return (
    <List sx={{ width: '100%' }}>
      <ListItem>
        <Grid container>
          <Grid item xs={8} md={10}>
            <Typography>{product.item.refName}</Typography>
          </Grid>
          <Grid item xs>
            <Typography>Remain:</Typography>
          </Grid>
          <Grid item xs>
            <Typography sx={{ textAlign: 'right' }}>
              {product.quantityRemaining}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container>
          <Grid item xs={8} md={10}>
            <Typography>{product.description}</Typography>
          </Grid>
          <Grid item xs>
            <Typography>
              {pathname.includes('picking') && 'Commit:'}
              {pathname.includes('receiving') && 'Received:'}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography sx={{ textAlign: 'right' }}>
              {pathname.includes('picking') && product.quantityCommitted}
              {pathname.includes('receiving') && product.quantityReceived}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container>
          <Grid item xs={8} md={10}>
            {pathname.includes('picking') && (
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ marginRight: '10px' }}>{`Bin: `}</Typography>
                {product.preferredBin ? (
                  product.preferredBin === 'loading' ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Typography>{product.preferredBin}</Typography>
                  )
                ) : (
                  <Typography>Scan to Display</Typography>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs>
            <Typography>{pathname.includes('picking') && 'Picked:'}</Typography>
          </Grid>
          <Grid item xs>
            <Typography sx={{ textAlign: 'right' }}>
              {pathname.includes('picking') && product.quantityPicked}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
      {pathname.includes('picking') && (
        <ListItem>
          <Grid container>
            <Grid item xs={8} md={10}></Grid>
            <Grid item xs>
              <Typography>Available:</Typography>
            </Grid>
            <Grid item xs>
              <Typography sx={{ textAlign: 'right' }}>
                {product.quantityAvailable}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </List>
  )
}

export default ProductCard
