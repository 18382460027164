import React, { useContext, useState } from 'react'
import { Button, TextField, Box, MenuItem } from '@mui/material'
import { addUser } from 'api'
import { useNavigate } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import Header from 'components/Header'
import { UserRole } from 'constant'
import { UserContext } from 'contexts/UserContextProvider'

const CreateUser = () => {
  const navigate = useNavigate()
  const { users, setUsers } = useContext(UserContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState<string>(UserRole.employee)
  const [error, setError] = useState(false)

  const handleCreateUser = async (
    username: string,
    password: string,
    email: string,
    role: string
  ) => {
    if (!!username && !!password && isEmail(email)) {
      try {
        const result: any = await addUser(username, password, email, role)
        const newUser = result.data.user
        setUsers([...users, newUser])
        if (result.data.success) {
          alert('User created successfully')
          navigate('/users')
        } else {
          alert('User created failed. Please check if the user already exists.')
        }
      } catch (error) {
        setError(true)
      }
    } else {
      setError(true)
    }
  }

  return (
    <>
      <Header />
      <Box
        sx={{
          width: '80%',
          margin: '0 auto',
          marginTop: '100px'
        }}
      >
        <TextField
          label='Username'
          fullWidth
          error={error && !username}
          required
          helperText={error && !username && 'Please enter your username.'}
          sx={{ margin: '20px auto 40px' }}
          onChange={e => {
            setError(false)
            setUsername(e.target.value)
          }}
        />
        <TextField
          label='Email'
          fullWidth
          type='email'
          error={error && (!email || !isEmail(email))}
          required
          helperText={
            error &&
            (!email || !isEmail(email)) &&
            'Please enter your valid email address.'
          }
          sx={{ margin: '20px auto 40px' }}
          onChange={e => {
            setError(false)
            setEmail(e.target.value)
          }}
        />
        <TextField
          label='Password'
          fullWidth
          type='password'
          required
          helperText={error && !password && 'Please enter your password.'}
          error={error && !password}
          sx={{ margin: '20px auto 40px' }}
          onChange={e => {
            setError(false)
            setPassword(e.target.value)
          }}
        />
        <TextField
          label='Role'
          fullWidth
          select
          required
          sx={{ margin: '20px auto 50px' }}
          onChange={e => {
            setError(false)
            setRole(e.target.value)
          }}
          defaultValue={UserRole.employee}
        >
          <MenuItem value={UserRole.admin}>{UserRole.admin}</MenuItem>
          <MenuItem value={UserRole.employee}>{UserRole.employee}</MenuItem>
        </TextField>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            handleCreateUser(username, password, email, role)
          }}
          sx={{
            display: 'block',
            margin: '20px auto',
            padding: '6px 25px',
            fontSize: 20
          }}
        >
          Submit
        </Button>
      </Box>
    </>
  )
}

export default CreateUser
