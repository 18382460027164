import { createTheme } from '@mui/material'

export const theme = createTheme({
  typography: {
    body1: {
      fontFamily: 'sans-serif'
    },
    body2: {
      fontFamily: 'sans-serif'
    }
  }
})
