import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Login from 'pages/Login'
import Home from 'pages/Home'
import PurchaseOrderReceiving from 'pages/PurchaseOrderReceiving'
import SalesOrderPicking from 'pages/SalesOrderPicking'
import OrderContextProvider from 'contexts/OrderContextProvider'
import { AuthContext } from 'contexts/AuthContextProvider'
import ChooseOperation from 'pages/ChooseOperation'
import CreateUser from 'pages/User/CreateUser'
import TransferOrder from 'pages/TransferOrderPicking'
import UserContextProvider from 'contexts/UserContextProvider'
import UserList from 'pages/User/UserList'
import UpdateUser from 'pages/User/UpdateUser'
import UserOperations from 'pages/UserOperations'
import UpdateOrderCache from 'pages/UpdateOrderCache'

const AppRouter = () => {
  const { isAuthorized } = useContext(AuthContext)
  return (
    <OrderContextProvider>
      <Routes>
        <Route path='/login' element={<Login />} />
        {isAuthorized ? (
          <>
            <Route path='/' element={<Home />} />
            <Route
              path='/:appRoot/sales-order-picking'
              element={<SalesOrderPicking />}
            />
            <Route
              path='/:appRoot/purchase-order-receiving'
              element={<PurchaseOrderReceiving />}
            />
            <Route
              path='/:appRoot/transfer-order-picking'
              element={<TransferOrder />}
            />
            <Route
              path='/:appRoot/update-order-cache'
              element={<UpdateOrderCache />}
            />
            <Route path='/:appRoot' element={<ChooseOperation />} />
            <Route
              path='/users/*'
              element={
                <UserContextProvider>
                  <Routes>
                    <Route path='/add-user' element={<CreateUser />} />
                    <Route
                      path='/update-user/:userId'
                      element={<UpdateUser />}
                    />
                    <Route path='*' element={<UserList />} />
                  </Routes>
                </UserContextProvider>
              }
            />
            <Route path='/user-operations' element={<UserOperations />} />
          </>
        ) : (
          <Route path='*' element={<Navigate to='/login' replace />} />
        )}
      </Routes>
    </OrderContextProvider>
  )
}

export default AppRouter
