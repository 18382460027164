const calculateQuantityPicked = (products: any[]) => {
  return products.reduce((sum, item) => sum + item.quantityPicked, 0)
}
const calculateQuantityRemaining = (products: any[]) => {
  return products.reduce((sum, item) => sum + item.quantityRemaining, 0)
}
const calculateQuantityReceived = (products: any[]) => {
  return products.reduce((sum, item) => sum + item.quantityReceived, 0)
}
const calculateQuantityNotReceived = (products: any[]) => {
  return products.reduce((sum, item) => sum + item.quantityRemaining, 0)
}
const isObjectNotEmpty = (obj: object): boolean => {
  return Object.keys(obj).length !== 0
}

const getPickingBackColor = (product: any) => {
  if (product.quantityPicked === 0 && product.quantityRemaining !== 0) {
    return 'rgba(252, 207, 207, 1)'
  }
  if (product.quantityPicked > 0 && product.quantityRemaining > 0) {
    return 'rgba(255, 255, 187, 1)'
  }
  if (product.quantityRemaining === 0) {
    return 'rgba(217, 252, 210, 1)'
  }
  return '#ffffff'
}
const getReceivingBackColor = (product: any) => {
  if (product.quantityReceived === 0 && product.quantityRemaining !== 0) {
    return 'rgba(252, 207, 207, 1)'
  }
  if (product.quantityReceived > 0 && product.quantityRemaining > 0) {
    return 'rgba(255, 255, 187, 1)'
  }
  if (product.quantityRemaining === 0) {
    return 'rgba(217, 252, 210, 1)'
  }
  return '#ffffff'
}

const getTransferOrderBackColor = (product: any) => {
  if (product.quantityPicked === 0 && product.quantityRemaining !== 0) {
    return 'rgba(252, 207, 207, 1)'
  }
  if (product.quantityPicked > 0 && product.quantityRemaining > 0) {
    return 'rgba(255, 255, 187, 1)'
  }
  if (product.quantityRemaining === 0) {
    return 'rgba(217, 252, 210, 1)'
  }
  return '#ffffff'
}

const setElementToTop = (array: any[], index: number) => {
  if (index > 0) {
    const elementToMove = array.splice(index, 1)[0]
    array.unshift(elementToMove)
  }
  return array
}

export {
  calculateQuantityPicked,
  calculateQuantityRemaining,
  calculateQuantityReceived,
  calculateQuantityNotReceived,
  isObjectNotEmpty,
  getPickingBackColor,
  getReceivingBackColor,
  setElementToTop,
  getTransferOrderBackColor
}
