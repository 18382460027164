import {
  getNetSuiteSalesOrder,
  getNetSuitePurchaseOrder,
  submitPicking,
  submitReceiving,
  deleteUserCache
} from 'api'
import React, { createContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { isObjectNotEmpty, setElementToTop } from 'utils'
import { useRef } from 'react'
import { appOptions, ItemScanError, operationPath } from 'constant'
import {
  findNetSuiteTransferOrder,
  getProductBin,
  submitPickingTransferOrder
} from 'api/netsuite'

interface Context {
  getOrderInformation: (orderNumber: string) => void
  orderSearchError: boolean
  setOrderSearchError: (error: boolean) => void
  loading: boolean
  handleScanProduct: (upc: string, setItemUpc: (upc: string) => void) => void
  itemSearchError: string
  setItemSearchError: (itemSearchError: string) => void
  orderContents: any
  setOrderContents: (data: any) => void
  products: any[]
  handleSubmitPicking: (
    setOpenDialog: (open: boolean) => void,
    setLoading: (loading: boolean) => void
  ) => void
  handleSubmitPurchaseOrderReceiving: (
    setOpenDialog: (open: boolean) => void,
    setLoading: (loading: boolean) => void
  ) => void
  orderNumber: string
  setOrderNumber: (orderNumber: string) => void
  inputRef: any
  snackbarAttributes: any
  setSnackbarAttributes: (snackbarAttributes: any) => void
  removeCurrentUserCache: () => void
  appRoot: string
  handleGetProductBin: (product: any) => void
  handleSubmitPickingTransferOrder: (
    setOpenDialog: (open: boolean) => void,
    setLoading: (loading: boolean) => void
  ) => void
}

export const OrderContext = createContext<Context>({
  getOrderInformation: () => {},
  orderSearchError: false,
  setOrderSearchError: () => {},
  loading: false,
  handleScanProduct: () => {},
  itemSearchError: '',
  setItemSearchError: () => {},
  orderContents: {},
  setOrderContents: () => {},
  products: [],
  handleSubmitPicking: () => {},
  handleSubmitPurchaseOrderReceiving: () => {},
  orderNumber: '',
  setOrderNumber: () => {},
  inputRef: {},
  snackbarAttributes: {},
  setSnackbarAttributes: () => {},
  removeCurrentUserCache: () => {},
  appRoot: '',
  handleGetProductBin: () => {},
  handleSubmitPickingTransferOrder: () => {}
})

interface Props {
  children: any
}

const OrderContextProvider = ({ children }: Props) => {
  const { pathname } = useLocation()
  const [orderSearchError, setOrderSearchError] = useState(false)
  const [itemSearchError, setItemSearchError] = useState('')
  const [loading, setLoading] = useState(false)
  const [orderNumber, setOrderNumber] = useState('')
  const [orderContents, setOrderContents] = useState<any>({})
  const [snackbarAttributes, setSnackbarAttributes] = useState({
    open: false,
    severity: 'success',
    contents: ''
  })
  const inputRef = useRef(null)
  const products = isObjectNotEmpty(orderContents) ? orderContents.item : []
  const appRoot = pathname.includes(appOptions.QTE.path)
    ? appOptions.QTE.name
    : appOptions.FCS.name
  const removeCurrentUserCache = async () => {
    await deleteUserCache(orderNumber, appRoot)
  }
  const getOrderInformation = async (orderNumber: string) => {
    setLoading(true)
    let result: any
    try {
      if (pathname.includes(operationPath.salesOrderPicking)) {
        result = await getNetSuiteSalesOrder(orderNumber, appRoot)
      } else if (pathname.includes(operationPath.purchaseOrderReceiving)) {
        result = await getNetSuitePurchaseOrder(orderNumber, appRoot)
      } else if (pathname.includes(operationPath.transferOrderPicking)) {
        result = await findNetSuiteTransferOrder(orderNumber, appRoot)
      } else {
        return
      }
      if (result.data.success) {
        setLoading(false)
        setOrderContents(result.data.data)
      } else {
        setLoading(false)
        setOrderSearchError(true)
      }
    } catch (err) {
      setLoading(false)
      setOrderSearchError(true)
      console.error(err)
    }
  }

  const handleGetProductBin = async (product: any) => {
    const result = await getProductBin(
      product.item.links[0].href,
      orderContents.location.id,
      appRoot
    )
    product.preferredBin = result.data.preferredBin
    const targetProductIndex = orderContents.item.findIndex(
      (item: any) => item.upcCode === product.upcCode
    )
    orderContents.item[targetProductIndex] = product
    setOrderContents({ ...orderContents })
  }
  const playErrorAlertAudio = () => {
    const audio = new Audio('/audio/error.wav')
    audio.play()
  }
  const handleScanProduct = (
    productUpc: string,
    setItemUpc: (productUpc: string) => void
  ) => {
    if (
      products.findIndex(
        (product: any) =>
          product.upcCode === productUpc || product.item.refName === productUpc
      ) === -1
    ) {
      playErrorAlertAudio()
      setItemSearchError(ItemScanError.INVALID_UPC)
      return
    }
    const targetProduct: any = products.find(
      (product: any) =>
        (product.upcCode === productUpc ||
          product.item.refName === productUpc) &&
        product.quantityRemaining > 0
    )
    if (targetProduct) {
      const productLine = targetProduct.line
      setItemSearchError('')
      if (
        pathname.includes(operationPath.salesOrderPicking) ||
        pathname.includes(operationPath.transferOrderPicking)
      ) {
        if (!targetProduct.preferredBin) {
          targetProduct.preferredBin = 'loading'
        }
        if (targetProduct.quantityAvailable > 0) {
          targetProduct.quantityRemaining--
          targetProduct.quantityPicked++
          targetProduct.quantityAvailable--
        } else {
          playErrorAlertAudio()
          setItemSearchError(ItemScanError.NO_INVENTORY)
          return
        }
      } else {
        targetProduct.quantityRemaining--
        targetProduct.quantityReceived++
      }
      const updatedProducts = [...products]
      const targetProductIndex = updatedProducts.findIndex(
        (product: any) => product.line === productLine
      )
      setElementToTop(updatedProducts, targetProductIndex)
      setOrderContents({
        ...orderContents,
        item: updatedProducts
      })
      setItemUpc('')
    } else {
      playErrorAlertAudio()
      setItemSearchError(ItemScanError.EXCEED_MAX)
    }
  }
  const handleSubmitPicking = async (
    setOpenDialog: (open: boolean) => void,
    setLoading: (loading: boolean) => void
  ) => {
    setLoading(true)
    const pickedItems = orderContents.item
      .filter((item: any) => item.quantityPicked > 0)
      .map((item: any) => {
        return { line: item.line, quantityPicked: item.quantityPicked }
      })
    const result = await submitPicking(
      orderContents.createdfrom.internalid,
      pickedItems,
      orderContents.location.id,
      appRoot
    )
    if (result.data.success) {
      removeCurrentUserCache()
      setLoading(false)
      setOrderContents({})
      setOrderNumber('')
      setOpenDialog(false)
      setSnackbarAttributes({
        open: true,
        severity: 'success',
        contents: 'Success!'
      })
    } else {
      setLoading(false)
      setOpenDialog(false)
      setSnackbarAttributes({
        open: true,
        severity: 'error',
        contents: 'Fail!'
      })
    }
  }

  const handleSubmitPickingTransferOrder = async (
    setOpenDialog: (open: boolean) => void,
    setLoading: (loading: boolean) => void
  ) => {
    setLoading(true)
    const pickedItems = orderContents.item
      .filter((item: any) => item.quantityPicked > 0)
      .map((item: any) => {
        return { itemId: item.item.id, quantityPicked: item.quantityPicked }
      })
    const result = await submitPickingTransferOrder(
      orderContents.createdfrom.internalid,
      pickedItems,
      appRoot
    )
    if (result.data.success) {
      removeCurrentUserCache()
      setLoading(false)
      setOrderContents({})
      setOrderNumber('')
      setOpenDialog(false)
      setSnackbarAttributes({
        open: true,
        severity: 'success',
        contents: 'Success!'
      })
    } else {
      setLoading(false)
      setOpenDialog(false)
      setSnackbarAttributes({
        open: true,
        severity: 'error',
        contents: 'Fail!'
      })
    }
  }

  const handleSubmitPurchaseOrderReceiving = async (
    setOpenDialog: (open: boolean) => void,
    setLoading: (loading: boolean) => void
  ) => {
    setLoading(true)
    const receivedItems = orderContents.item
      .filter((item: any) => item.quantityReceived > 0)
      .map((item: any) => {
        return {
          line: item.line,
          quantityReceived: item.quantityReceived,
          quantityRemaining: item.quantityRemaining,
          item: { id: item.item.id, refName: item.item.refName }
        }
      })
    const result = await submitReceiving(
      receivedItems,
      orderContents.location,
      appRoot,
      orderContents.id
    )
    if (result.data.success) {
      removeCurrentUserCache()
      setLoading(false)
      setOrderContents({})
      setOrderNumber('')
      setOpenDialog(false)
      setSnackbarAttributes({
        open: true,
        severity: 'success',
        contents: 'Success!'
      })
    } else {
      setLoading(false)
      setOpenDialog(false)
      setSnackbarAttributes({
        open: true,
        severity: 'error',
        contents: 'Fail!'
      })
    }
  }

  return (
    <OrderContext.Provider
      value={{
        getOrderInformation,
        orderSearchError,
        setOrderSearchError,
        loading,
        handleScanProduct,
        itemSearchError,
        setItemSearchError,
        orderContents,
        setOrderContents,
        products,
        handleSubmitPicking,
        handleSubmitPurchaseOrderReceiving,
        orderNumber,
        setOrderNumber,
        inputRef,
        snackbarAttributes,
        setSnackbarAttributes,
        removeCurrentUserCache,
        appRoot,
        handleGetProductBin,
        handleSubmitPickingTransferOrder
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

export default OrderContextProvider
