import { axios } from 'api'

const getOperationsCount = (fromDate?: string, toDate?: string) =>
  axios.get('/operations/count', {
    params: { fromDate, toDate }
  })

const getOperationsByPaginationAndDate = (
  pageSize: number,
  pageNumber: number,
  fromDate?: string,
  toDate?: string
) =>
  axios.get('/operations/pagination-date', {
    params: { pageSize, pageNumber, fromDate, toDate }
  })

const downloadOperationsExcel = (fromDate?: string, toDate?: string) =>
  axios.get('/operations/download', {
    params: { fromDate, toDate },
    responseType: 'blob'
  })

export {
  getOperationsCount,
  getOperationsByPaginationAndDate,
  downloadOperationsExcel
}
