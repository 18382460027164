import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material'
import { useContext } from 'react'
import { UserContext } from 'contexts/UserContextProvider'
import { Link, useNavigate } from 'react-router-dom'
import Header from 'components/Header'
import { deleteUser } from 'api'

const UserList = () => {
  const navigate = useNavigate()
  const { users, setUsers } = useContext(UserContext)
  const handleDeleteUser = async (userId: string) => {
    try {
      await deleteUser(userId)
      setUsers(users.filter(user => user._id !== userId))
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <Header />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='users table'>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.username}>
                <TableCell component='th' scope='row'>
                  {user.username}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {user.email}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {user.role}
                </TableCell>
                <TableCell align='right'>
                  <Button
                    onClick={() => {
                      navigate(`/users/update-user/${user._id}`)
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      handleDeleteUser(user._id)
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant='contained'
        color='primary'
        style={{ display: 'block', margin: '20px auto' }}
      >
        <Link
          to='/users/add-user'
          style={{ color: '#fff', textDecoration: 'none', fontSize: '20px' }}
        >
          Create New User
        </Link>
      </Button>
      <Button
        variant='outlined'
        color='primary'
        style={{ display: 'block', margin: '20px auto' }}
      >
        <Link
          to='/'
          style={{ color: '#1976d2', textDecoration: 'none', fontSize: '20px' }}
        >
          Back to Home
        </Link>
      </Button>
    </>
  )
}

export default UserList
