import { axios } from 'api'
import Cookies from 'js-cookie'

const deleteUserCache = (orderNumber: string, appRoot: string) =>
  axios
    .get(`/cache/delete-user-cache`, {
      params: {
        orderNumber,
        appRoot
      },
      headers: {
        Cookie: `userCookie=${Cookies.get('userCookie')}`
      }
    })
    .catch(err => {
      console.error(err)
    })

export { deleteUserCache }
