import { getAllUser } from 'api'
import React, { createContext, useEffect, useState } from 'react'

interface Context {
  users: any[]
  setUsers: (users: any[]) => void
}
export const UserContext = createContext<Context>({
  users: [],
  setUsers: () => {}
})

interface Props {
  children: any
}

const UserContextProvider = ({ children }: Props) => {
  const [users, setUsers] = useState<any[]>([])
  useEffect(() => {
    const getUsers = async () => {
      const usersResult = (await getAllUser()).data.users
      setUsers(usersResult)
    }
    getUsers()
  }, [])

  return (
    <UserContext.Provider
      value={{
        users,
        setUsers
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
