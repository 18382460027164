import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { OrderContext } from 'contexts/OrderContextProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  calculateQuantityNotReceived,
  calculateQuantityPicked,
  calculateQuantityReceived,
  calculateQuantityRemaining,
  isObjectNotEmpty
} from 'utils'
import { operationPath } from 'constant'

interface Props {
  label?: string
}

const Header = ({ label }: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    setOrderContents,
    setOrderSearchError,
    orderContents,
    products,
    handleSubmitPicking,
    handleSubmitPurchaseOrderReceiving,
    setOrderNumber,
    inputRef,
    snackbarAttributes,
    setSnackbarAttributes,
    removeCurrentUserCache,
    setItemSearchError,
    handleSubmitPickingTransferOrder
  } = useContext(OrderContext)
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    inputRef?.current?.focus()
  }, [openDialog])

  return (
    <Box sx={{ backgroundColor: '#607998', padding: '5px' }}>
      <Grid
        container
        sx={{
          '& button, button:focus, button:active': {
            backgroundColor: '#24385b',
            color: '#ffffff'
          }
        }}
      >
        <Grid item xs>
          {isObjectNotEmpty(orderContents) ? (
            <Button
              variant='contained'
              onClick={async () => {
                removeCurrentUserCache()
                setOrderContents({})
                setOrderNumber('')
                setItemSearchError('')
              }}
            >
              Cancel
            </Button>
          ) : (
            <Button
              variant='contained'
              onClick={() => {
                navigate(-1)
                setOrderSearchError(false)
                setOrderNumber('')
              }}
            >
              Back
            </Button>
          )}
        </Grid>
        <Grid item xs>
          <Typography
            sx={{ color: '#ffffff', fontWeight: 600, lineHeight: '36px' }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            '& .Mui-disabled': {
              color: '#A9A9A9'
            }
          }}
        >
          {isObjectNotEmpty(orderContents) && (
            <Button
              onClick={() => {
                setOpenDialog(true)
              }}
              disabled={
                pathname.includes(operationPath.salesOrderPicking) ||
                pathname.includes(operationPath.transferOrderPicking)
                  ? !calculateQuantityPicked(products)
                  : !calculateQuantityReceived(products)
              }
            >
              Submit
            </Button>
          )}
        </Grid>
      </Grid>
      <Dialog open={openDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          {pathname.includes(operationPath.salesOrderPicking) ||
          pathname.includes(operationPath.transferOrderPicking) ? (
            <DialogContentText id='alert-dialog-description'>
              Total Quantity Picked: {calculateQuantityPicked(products)}
              <br />
              Total Quantity Remaining: {calculateQuantityRemaining(products)}
            </DialogContentText>
          ) : (
            <DialogContentText id='alert-dialog-description'>
              Total Quantity Received: {calculateQuantityReceived(products)}
              <br />
              Total Quantity Remaining: {calculateQuantityNotReceived(products)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setLoading(false)
              setOpenDialog(false)
            }}
          >
            Disagree
          </Button>
          <LoadingButton
            loading={loading}
            variant='outlined'
            onClick={() => {
              if (pathname.includes(operationPath.salesOrderPicking)) {
                handleSubmitPicking(setOpenDialog, setLoading)
              } else if (
                pathname.includes(operationPath.purchaseOrderReceiving)
              ) {
                handleSubmitPurchaseOrderReceiving(setOpenDialog, setLoading)
              } else if (
                pathname.includes(operationPath.transferOrderPicking)
              ) {
                handleSubmitPickingTransferOrder(setOpenDialog, setLoading)
              } else {
                return
              }
            }}
          >
            Agree
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        sx={{ marginTop: '40px' }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarAttributes.open}
        onClose={(event?: React.SyntheticEvent | Event, reason?: string) => {
          if (reason === 'clickaway') {
            return
          }
          setSnackbarAttributes({ ...snackbarAttributes, open: false })
        }}
      >
        <Alert severity={snackbarAttributes.severity} sx={{ width: '80%' }}>
          {snackbarAttributes.contents}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Header
