const portalOptions = [
  { label: 'QTE Auto', path: '/QTE' },
  { label: 'FCS Auto', path: '/FCS' }
]
const appOptions = {
  QTE: { name: 'QTE', path: '/QTE' },
  FCS: { name: 'FCS', path: '/FCS' }
}
const operationOptions = [
  { label: 'Sales Order Picking', path: '/sales-order-picking' },
  { label: 'Purchase Order Receiving', path: '/purchase-order-receiving' },
  { label: 'Transfer Order Picking', path: '/transfer-order-picking' },
  { label: 'Update Order Cache', path: '/update-order-cache' }
]
enum operationPath {
  salesOrderPicking = '/sales-order-picking',
  purchaseOrderReceiving = '/purchase-order-receiving',
  transferOrderPicking = '/transfer-order-picking'
}
enum ItemScanError {
  EXCEED_MAX = 'exceed_max',
  INVALID_UPC = 'invalid_upc',
  NO_INVENTORY = 'no_inventory'
}
enum UserRole {
  admin = 'admin',
  employee = 'employee'
}
export {
  operationOptions,
  portalOptions,
  appOptions,
  ItemScanError,
  operationPath,
  UserRole
}
