import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import Header from 'components/Header'
import OrderSearchInput from 'components/OrderSearchInput'
import { ProductCard, ProductListSearchBar } from 'components/ProductList'
import { OrderContext } from 'contexts/OrderContextProvider'
import React, { useContext, useState } from 'react'
import { isObjectNotEmpty, getReceivingBackColor } from 'utils'

const PurchaseOrderReceiving = () => {
  const { orderContents, products, orderNumber } = useContext(OrderContext)
  const [scrollIndex, setScrollIndex] = useState(1)
  return (
    <>
      <Header label='Purchase Order Receiving' />
      {isObjectNotEmpty(orderContents) ? (
        <Box>
          <Box sx={{ backgroundColor: 'rgba(191,205,224,1)', padding: '5px' }}>
            <Typography
              sx={{ textAlign: 'center' }}
            >{`Purchase order: ${orderNumber}`}</Typography>
          </Box>
          <ProductListSearchBar />
          <Box sx={{ margin: '20px 10px 10px' }}>
            <Typography>
              To location: {orderContents.location.refName}
            </Typography>
          </Box>
          {products.length > 0 ? (
            <List
              onScroll={(event: any) => {
                const target = event.target
                if (
                  target.scrollHeight -
                    target.scrollTop -
                    target.clientHeight <=
                  0
                ) {
                  setScrollIndex(scrollIndex + 1)
                }
              }}
            >
              {products.slice(0, 10 * scrollIndex).map((product: any) => (
                <Box key={product.line}>
                  <ListItem
                    sx={{ backgroundColor: getReceivingBackColor(product) }}
                  >
                    <ProductCard product={product} />
                  </ListItem>
                  <Divider color='#ccc' />
                </Box>
              ))}
            </List>
          ) : (
            <Typography variant='h2'>
              All items in this order were received!
            </Typography>
          )}
        </Box>
      ) : (
        <OrderSearchInput />
      )}
    </>
  )
}

export default PurchaseOrderReceiving
