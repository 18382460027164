import { axios } from 'api'

const getAllUser = () => axios.get('/users')

const addUser = (
  username: string,
  password: string,
  email: string,
  role: string
) =>
  axios
    .post(`/users/add`, {
      username,
      password,
      email,
      role
    })
    .catch(err => {
      console.error(err)
    })
const updateUser = (
  userId: string,
  password: string,
  email: string,
  role: string
) =>
  axios
    .put(`/users`, {
      userId,
      password,
      email,
      role
    })
    .catch(err => {
      console.error(err)
    })

const deleteUser = (userId: string) =>
  axios
    .delete(`/users`, {
      data: { userId }
    })
    .catch(err => {
      console.error(err)
    })

export { addUser, getAllUser, updateUser, deleteUser }
