import { Box, Button, List, ListItem } from '@mui/material'
import Header from 'components/Header'
import { operationOptions, appOptions } from 'constant'
import { OrderContext } from 'contexts/OrderContextProvider'
import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { isObjectNotEmpty } from 'utils'

const ChooseOperation = () => {
  const { pathname } = useLocation()
  const { setOrderContents, orderContents } = useContext(OrderContext)
  useEffect(() => {
    if (isObjectNotEmpty(orderContents)) setOrderContents({})
  }, [])

  return (
    <Box>
      <Header />
      <List
        sx={{
          '& .MuiListItemButton-root': {
            color: '#607998',
            fontFamily: 'sans-serif',
            fontWeight: 600
          },
          '& a': {
            textDecoration: 'none'
          }
        }}
      >
        {operationOptions.map(item => (
          <Box key={item.label}>
            <Link
              to={
                pathname.includes(appOptions.FCS.path)
                  ? appOptions.FCS.path + item.path
                  : appOptions.QTE.path + item.path
              }
            >
              <ListItem sx={{ padding: '60px 48px 20px' }}>
                <Button
                  variant='contained'
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    textTransform: 'none',
                    fontSize: '26px',
                    padding: '50px 20px',
                    backgroundColor: '#364c63'
                  }}
                >
                  {item.label}
                </Button>
              </ListItem>
            </Link>
          </Box>
        ))}
      </List>
    </Box>
  )
}

export default ChooseOperation
