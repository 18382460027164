import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  Box,
  Button,
  Typography
} from '@mui/material'
import Header from 'components/Header'
import { Operation } from 'models'
import {
  downloadOperationsExcel,
  getOperationsByPaginationAndDate,
  getOperationsCount
} from 'api'
import moment from 'moment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const UserOperations = () => {
  const [operations, setOperations] = useState<Operation[]>([])
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(20)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()

  useEffect(() => {
    const getInitialData = async () => {
      const countResult = await getOperationsCount()
      const operationsResult = await getOperationsByPaginationAndDate(
        pageSize,
        page
      )
      setTotalCount(countResult.data.count)
      setOperations(operationsResult.data.operations)
    }
    getInitialData()
  }, [])
  useEffect(() => {
    const updateOperations = async () => {
      const countResult = await getOperationsCount(fromDate, toDate)
      const operationsResult = await getOperationsByPaginationAndDate(
        pageSize,
        page,
        fromDate,
        toDate
      )
      setTotalCount(countResult.data.count)
      setOperations(operationsResult.data.operations)
    }
    updateOperations()
  }, [page, pageSize, fromDate, toDate])

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          margin: '20px 30px'
        }}
      >
        <Button
          variant='contained'
          sx={{
            backgroundColor: '#607998',
            marginRight: '20px'
          }}
          disabled={!fromDate}
          onClick={async () => {
            try {
              const response = await downloadOperationsExcel(fromDate, toDate)
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute(
                'download',
                `Operations_From_${fromDate}_To_${
                  toDate || moment().format('YYYY-MM-DD')
                }.xlsx`
              )
              document.body.appendChild(link)
              link.click()
              link.parentNode && link.parentNode.removeChild(link)
              window.URL.revokeObjectURL(url) // 释放URL对象
            } catch (error) {
              console.error('There was an error!', error)
            }
          }}
        >
          Export Operations
        </Button>
        <Box sx={{ marginRight: '20px' }}>
          <Typography>From</Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              maxDate={moment(toDate) || moment()}
              onChange={(newValue: any) =>
                setFromDate(newValue.format('YYYY-MM-DD'))
              }
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <Typography>To</Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              minDate={moment(fromDate)}
              maxDate={moment()}
              disabled={!fromDate && true}
              onChange={(newValue: any) =>
                setToDate(newValue.format('YYYY-MM-DD'))
              }
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label='users table'>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Order TranId</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operations.map((operation: Operation) => {
              return (
                <TableRow key={operation._id}>
                  <TableCell component='th' scope='row'>
                    {operation.username}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {operation.orderNumber}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {moment(operation.createdAt)
                      .utcOffset('-05:00')
                      .format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={totalCount}
                onPageChange={(event: unknown, newPage: number) => {
                  setPage(newPage)
                }}
                rowsPerPageOptions={[5, 10, 20]}
                rowsPerPage={pageSize}
                page={page}
                onRowsPerPageChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setPageSize(+event.target.value)
                  setPage(0)
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

export default UserOperations
