import { axios } from 'api'
import Cookies from 'js-cookie'

const getNetSuiteSalesOrder = (orderNumber: string, appRoot: string) =>
  axios.get(`/netsuite/sales-order`, {
    params: {
      orderNumber,
      appRoot
    },
    headers: {
      Cookie: `userCookie=${Cookies.get('userCookie')}`
    }
  })

const getProductBin = (url: string, locationId: number, appRoot: string) =>
  axios.get(`/netsuite/product-bin`, {
    params: {
      url,
      locationId,
      appRoot
    },
    headers: {
      Cookie: `userCookie=${Cookies.get('userCookie')}`
    }
  })

const getNetSuitePurchaseOrder = (orderNumber: string, appRoot: string) =>
  axios.get(`/netsuite/purchase-order`, {
    params: {
      orderNumber,
      appRoot
    },
    headers: {
      Cookie: `userCookie=${Cookies.get('userCookie')}`
    }
  })

const findNetSuiteTransferOrder = (orderNumber: string, appRoot: string) =>
  axios.get(`/netsuite/find-transfer-order`, {
    params: {
      orderNumber,
      appRoot
    },
    headers: {
      Cookie: `userCookie=${Cookies.get('userCookie')}`
    }
  })

const submitPicking = (
  orderId: string,
  pickedItems: any[],
  locationId: string,
  appRoot: string
) =>
  axios.post(
    `/netsuite/submit-picking`,
    {
      orderId,
      pickedItems,
      locationId,
      appRoot
    },
    {
      headers: {
        Cookie: `userCookie=${Cookies.get('userCookie')}`
      }
    }
  )

const submitReceiving = (
  items: any,
  location: any,
  appRoot: string,
  orderId: string
) =>
  axios.post(
    `/netsuite/submit-receiving`,
    {
      items,
      location,
      appRoot,
      orderId
    },
    {
      headers: {
        Cookie: `userCookie=${Cookies.get('userCookie')}`
      }
    }
  )
const submitPickingTransferOrder = (
  orderId: string,
  pickedItems: any[],
  appRoot: string
) =>
  axios.post(
    `/netsuite/submit-transfer-order`,
    {
      orderId,
      pickedItems,
      appRoot
    },
    {
      headers: {
        Cookie: `userCookie=${Cookies.get('userCookie')}`
      }
    }
  )

export {
  getNetSuiteSalesOrder,
  getNetSuitePurchaseOrder,
  findNetSuiteTransferOrder,
  submitPicking,
  submitReceiving,
  getProductBin,
  submitPickingTransferOrder
}
