import React, { useContext, useEffect, useState } from 'react'
import { Button, TextField, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'contexts/AuthContextProvider'

const Login = () => {
  const { isAuthorized, handleLogin, error, setError } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const handleEnterKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleLogin(username, password)
    }
  }

  useEffect(() => {
    if (isAuthorized) {
      navigate('/')
    }
  })

  return (
    <Box onKeyDown={handleEnterKeyDown} sx={{ marginTop: '100px' }}>
      <TextField
        label='Username'
        margin='normal'
        fullWidth
        error={error}
        sx={{ display: 'block', width: '80%', margin: '20px auto 40px' }}
        onChange={e => {
          setError(false)
          setUsername(e.target.value)
        }}
      />
      <TextField
        label='Password'
        fullWidth
        margin='normal'
        type='password'
        error={error}
        sx={{ display: 'block', width: '80%', margin: '20px auto 50px' }}
        helperText={error && 'Invalid username or password'}
        onChange={e => {
          setError(false)
          setPassword(e.target.value)
        }}
      />
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          handleLogin(username, password)
        }}
        sx={{
          display: 'block',
          margin: '20px auto',
          padding: '6px 25px',
          fontSize: 20
        }}
      >
        Login
      </Button>
    </Box>
  )
}

export default Login
