import { Box, TextField, Backdrop, CircularProgress } from '@mui/material'
import { OrderContext } from 'contexts/OrderContextProvider'
import React, { useContext, useEffect } from 'react'

const OrderSearchInput = () => {
  const {
    getOrderInformation,
    orderSearchError,
    setOrderSearchError,
    loading,
    orderNumber,
    setOrderNumber,
    inputRef,
    orderContents
  } = useContext(OrderContext)
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      getOrderInformation(orderNumber)
    }
  }
  useEffect(() => {
    inputRef.current.focus()
  }, [orderContents])

  return (
    <Box
      onKeyDown={handleKeyDown}
      sx={{
        margin: '50px 20px'
      }}
    >
      <TextField
        autoFocus
        inputRef={inputRef}
        label='Order Number'
        fullWidth
        value={orderNumber || ''}
        error={orderSearchError}
        helperText={orderSearchError && 'Invalid order number'}
        onChange={e => {
          if (orderSearchError) {
            setOrderSearchError(false)
          }
          setOrderNumber(e.target.value)
        }}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </Box>
  )
}

export default OrderSearchInput
