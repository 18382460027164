import { Alert, Box, Snackbar, TextField, Typography } from '@mui/material'
import { ItemScanError } from 'constant'
import { OrderContext } from 'contexts/OrderContextProvider'
import React, { useContext, useEffect, useState } from 'react'

const ProductListSearchBar = () => {
  const { handleScanProduct, itemSearchError, setItemSearchError, inputRef } =
    useContext(OrderContext)
  const [itemUpc, setItemUpc] = useState('')
  const [openSnack, setOpenSnack] = useState(false)
  const getSnackContent = (itemSearchError: string) => {
    switch (itemSearchError) {
      case ItemScanError.EXCEED_MAX:
        return 'Item input has exceeded the maximum value!'
      case ItemScanError.INVALID_UPC:
        return 'Wrong upc input, cannot find item!'
      case ItemScanError.NO_INVENTORY:
        return 'Not enough inventory available!'
      default:
        break
    }
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleScanProduct(itemUpc, setItemUpc)
    }
  }
  useEffect(() => {
    if (itemSearchError) {
      setOpenSnack(true)
    }
  }, [itemSearchError])
  return (
    <Box
      sx={{
        backgroundColor: '#dfe4eb',
        padding: '5px 10px',
        '& input': {
          backgroundColor: '#fff'
        }
      }}
      onKeyDown={handleKeyDown}
    >
      <Typography>Item:</Typography>
      <TextField
        fullWidth
        autoFocus
        inputRef={inputRef}
        size='small'
        value={itemUpc}
        error={!!itemSearchError}
        disabled={openSnack}
        onChange={e => {
          if (itemSearchError !== '') {
            const oldValue = itemUpc
            setItemUpc(e.target.value.substring(oldValue.length))
            setItemSearchError('')
          } else {
            setItemUpc(e.target.value)
          }
          setOpenSnack(false)
        }}
      />
      <Snackbar
        sx={{ marginTop: '40px' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={(event?: React.SyntheticEvent | Event, reason?: string) => {
          if (reason === 'clickaway') {
            setOpenSnack(false)
            return
          }
        }}
      >
        <Alert severity='error' sx={{ width: '80%' }}>
          {getSnackContent(itemSearchError)}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ProductListSearchBar
