import { axios } from 'api'

const login = (username: string, password: string) =>
  axios
    .post(`/auth/login`, {
      username,
      password
    })
    .catch(err => {
      console.error(err)
    })

export { login }
