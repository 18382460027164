import { Box, Button, List, ListItem } from '@mui/material'
import { Link } from 'react-router-dom'
import { portalOptions } from 'constant'
import React from 'react'
import { logout } from 'utils/auth'

const Home = () => {
  const userIsAdmin = localStorage.getItem('userIsAdmin')
  return (
    <Box>
      <List
        sx={{
          '& .MuiListItemButton-root': {
            color: '#607998',
            fontFamily: 'sans-serif',
            fontWeight: 600
          },
          '& a': {
            textDecoration: 'none'
          }
        }}
      >
        {portalOptions.map(item => (
          <Box key={item.label}>
            <Link to={item.path}>
              <ListItem sx={{ marginTop: '60px' }}>
                <Button
                  variant='contained'
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    textTransform: 'none',
                    fontSize: '26px',
                    padding: '50px 30px',
                    backgroundColor: '#364c63'
                  }}
                >
                  {item.label}
                </Button>
              </ListItem>
            </Link>
          </Box>
        ))}
        {userIsAdmin === 'true' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box sx={{ marginTop: '60px' }}>
              <Link to='users'>
                <Button
                  variant='contained'
                  sx={{
                    margin: '0 auto',
                    textAlign: 'center',
                    textTransform: 'none',
                    fontSize: '26px',
                    padding: '15px 50px',
                    backgroundColor: '#364c63'
                  }}
                >
                  User List
                </Button>
              </Link>
            </Box>
            <Box sx={{ marginTop: '60px' }}>
              <Link to='user-operations'>
                <Button
                  variant='contained'
                  sx={{
                    margin: '0 auto',
                    textAlign: 'center',
                    textTransform: 'none',
                    fontSize: '26px',
                    padding: '15px 50px',
                    backgroundColor: '#364c63'
                  }}
                >
                  User Operations
                </Button>
              </Link>
            </Box>
          </Box>
        )}
        <ListItem sx={{ padding: '60px 48px 20px' }}>
          <Button
            variant='contained'
            sx={{
              margin: '0 auto',
              textAlign: 'center',
              textTransform: 'none',
              fontSize: '26px',
              padding: '10px 20px',
              backgroundColor: '#364c63'
            }}
            onClick={logout}
          >
            Logout
          </Button>
        </ListItem>
      </List>
    </Box>
  )
}

export default Home
