import axios from 'axios'
import { login } from './login'
import {
  getNetSuiteSalesOrder,
  getNetSuitePurchaseOrder,
  submitPicking,
  submitReceiving
} from './netsuite'
import { deleteUserCache } from './cache'
import { logout } from 'utils/auth'
import { addUser, getAllUser, updateUser, deleteUser } from './user'
import {
  getOperationsCount,
  getOperationsByPaginationAndDate,
  downloadOperationsExcel
} from './operation'
import { updateRedisOrder } from './redis'

axios.defaults.withCredentials = true
axios.defaults.baseURL = `${process.env.REACT_APP_BASE_SERVER_HOST}/api`
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      if (error.response.status === 403) {
        alert('Unauthorized. Logging out...')
        logout()
      }
    }
    return Promise.reject(error)
  }
)

export {
  axios,
  login,
  getNetSuiteSalesOrder,
  getNetSuitePurchaseOrder,
  submitPicking,
  submitReceiving,
  deleteUserCache,
  addUser,
  getAllUser,
  updateUser,
  deleteUser,
  getOperationsCount,
  getOperationsByPaginationAndDate,
  downloadOperationsExcel,
  updateRedisOrder
}
