import React, { createContext, useState } from 'react'
import Cookies from 'js-cookie'
import { login } from 'api'
import { useNavigate } from 'react-router-dom'

interface Context {
  isAuthorized: boolean
  setIsAuthorized: (isAuthorized: boolean) => void
  error: boolean
  setError: (error: boolean) => void
  handleLogin: (username: string, password: string) => void
}
export const AuthContext = createContext<Context>({
  isAuthorized: false,
  setIsAuthorized: () => {},
  error: false,
  setError: () => {},
  handleLogin: () => {}
})

interface Props {
  children: any
}

const AuthContextProvider = ({ children }: Props) => {
  const navigate = useNavigate()
  const [isAuthorized, setIsAuthorized] = useState(
    Cookies.get('userCookie') ? true : false
  )
  const [error, setError] = useState(false)
  const handleLogin = async (username: string, password: string) => {
    try {
      const result: any = await login(username, password)
      if (result.data.success) {
        localStorage.setItem('userIsAdmin', result.data.admin)
        setIsAuthorized(true)
        navigate('/')
      } else {
        setError(true)
      }
    } catch (err) {
      setError(true)
      console.error(err)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthorized,
        setIsAuthorized,
        error,
        setError,
        handleLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
