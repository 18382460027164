import React from 'react'
import AppRouter from 'AppRouter'
import { ThemeProvider, Box } from '@mui/material'
import { theme } from 'muiTheme'
import AuthContextProvider from 'contexts/AuthContextProvider'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <Box className='App'>
          <AppRouter />
        </Box>
      </AuthContextProvider>
    </ThemeProvider>
  )
}

export default App
